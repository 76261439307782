import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <script
        src="https://kit.fontawesome.com/0842dd585c.js"
        crossorigin="anonymous"
      ></script>
    </Helmet>
    <SEO title="Strona główna" />
    <div className="container">
      <div className="main">
        <div className="row">
          <div className="col-md-4">
            <Link to="/promotion">
              <div className="box">
                <i className="fas fa-percent"></i>
                <h2>Aktualne promocje</h2>
                <p>Sprawdź aktualne ceny specjalne na wybrane produkty.</p>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link to="/advertising-materials">
              <div className="box">
                <i className="fas fa-map"></i>
                <h2>Materiały reklamowe</h2>
                <p>
                  Pobierz ulotki, plakaty i katalogi. Wydrukuj i używaj na cele
                  marketingowe w swojej firmie.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link to="/pricing">
              <div className="box">
                <i className="fas fa-dollar-sign"></i>
                <h2>Cenniki</h2>
                <p>Ceny w jednym miejscu. Zawsze kiedy potrzebujesz.</p>
              </div>
            </Link>
          </div>

          <div className="col-md-4">
            <Link to="/contract">
              <div className="box">
                <i className="fas fa-file-alt"></i>
                <h2>Umowy</h2>
                <p>
                  W tym miejscu możesz zapoznać się ze wzorami naszych umów
                  handlowych.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link to="/cooperation-rules">
              <div className="box">
                <i className="far fa-handshake"></i>
                <h2>Zasady współpracy</h2>
                <p>Poznaj nas bliżej. Dowiedz się, jak działamy.</p>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <a href="https://blog.fototransfer.pl">
              <div className="box">
                <i className="far fa-newspaper"></i>
                <h2>Blog</h2>
                <p>W tym miejscu inspirujemy. Warto poczytać.</p>
              </div>
            </a>
          </div>

          <div className="col-md-6">
            <a href="https://pomoc.fototransfer.pl/">
              <div className="box">
                <i className="fas fa-question-circle"></i>
                <h2>FAQ</h2>
                <p>
                  Najczęściej zadawane pytania. Wszystko w jednym miejscu, żebyś
                  nie musiał szukać.
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-6">
            <a href="https://www.fototransfer.pl/dla-partnerow">
              <div className="box">
                <i className="far fa-newspaper"></i>
                <h2>Nowości</h2>
                <p>Bądź na bieżąco. Dowiedz się, co mamy nowego w ofercie.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* <Link to="/promotion/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
